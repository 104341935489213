
.prodaction-items{
    display: flex;
    flex-wrap: wrap;
}

.prodaction-content{
    margin-bottom: 30px;
}

.prodaction-content-exec{
    padding: 20px;
    background-color: #fff5e7;

    & > h2{
        margin-bottom: 20px;
        font-size: 20px;
        font-weight: bold;
    }
}

.prodaction-item{
    display: block;
    background-color: #fff;
    box-shadow: 0 0 5px rgba(0,0,0,0.25);
    padding: 15px;
    border-radius: 5px;
    margin-right: 15px;
    margin-bottom: 20px;
    width: 450px;
    max-width: calc(100% - 20px);    

    .prodaction-item__title{
        font-size: 16px;
        color: $color;
        margin-bottom: 10px;

        a{
            color: $color;
            font-weight: bold;
        }

    }
    

    .prodaction-item__client-info{
        margin-bottom: 10px;
    }

    .prodaction-item__client-info-item{
        font-size: 13px;
        margin-bottom: 0px;        
    }

    .prodaction-item__order-info{
        margin-bottom: 15px;
    }

    .prodaction-item__order-info-list{
        display: block;
        margin-bottom: 20px;
        padding: 15px;
        background-color: #fcfcfc;
        border: 2px solid #e7e7e7;
    }

    .prodaction-item__role-item{
        display: flex;
        align-items: center;
        justify-content: space-between;

        .input-wrap{
            width: calc(50% - 5px);   
            input, select{
                min-width: 100px;
            }         

            &.no-input{
                input, select{
                    background-color: #e7e7e7;
                    pointer-events: none;

                } 
            }
        }
    }
}