.modal-edit__wrap{
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s linear;    

    background-color: rgba(0,0,0,0.35);
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    

    .modal-edit__modal{
        transform: translateX(-50%);
        position: fixed;
        left: 50%;
        top: 70px;
        width: 650px;
        height: auto;
        max-height: 80vh;
        max-width: 90%;
        z-index: 1000;
        background-color: #fff;
        box-shadow: 0 0px 10px rgba(0,0,0,0.3);
        transition: all 0.3s ease;   
        overflow-y: auto;
        //border-radius: 15px;
    }

    .modal-close{
        font-size: 15px;
        font-weight: bold;
        color: #111;
        margin-bottom: 20px;
        cursor: pointer;
        position: absolute;
        right: 15px;
        top: 10px;
        z-index: 100;
        //top: 10px;
    }

    .modal-edit__content{
        padding: 30px 0px;
        padding-bottom: 25px;
        padding-top: 50px;

        overflow-y: hidden;
        height: 100%;
        //padding-bottom: 125px;
        form{
            height: 100%;
            overflow-y: auto;
        }
    }

    &.active{
        opacity: 1;
        visibility: visible;
        transition: opacity 0.3s ease, visibility 0.3s linear;
    }
}