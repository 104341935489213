.input-wrap{
    position: relative;
    margin-bottom: 15px;
    .input-name{
        position: absolute;
        font-size: 12px;
        top: 5px;
        left: 12px;
        z-index: 20;
        color: #8b8b8b;
        font-weight: 400;

        ._name-required{
            display: inline-block;
            color: red;
            position: relative;
            &:before{
                content: "Обязательное";
                background-color: #fff;
                padding: 4px 5px;
                box-shadow: 0 0 3px rgba(0,0,0, 0.25);
                border-radius: 3px;
                display: none;
                position: absolute;
                bottom: 0;
                transform: translate(-50%, 100%);
            }

            &:hover{                
                &:before{
                    display: block;
                }
            }
        }
    }
     input{
        width: 100%;
        border: 2px solid #e7e7e7;
        background-color: #fcfcfc;
        outline: none;
        background-color: #fff;
        padding: 25px 12px 8px 12px ;
        font-size: 16px;
        color: #111;
        border-radius: 5px;
        min-width: 120px;
        font-weight: 500;
        display: block;       
        font-size: 14px;
        color: #888; 
    }

    &.checkbox{
        position: relative;
        input{
            display: none;
        }

        label{
            padding-left: 30px;
            font-size: 17px;
            line-height: 1.35;
        }


        label:before{
            content: "";
            position: absolute;
            background: 0 0;
            border-radius: 3px;
            cursor: pointer;
            z-index: 3;
            border: 2px solid #cacaca;
            height: 20px;
            width: 20px;
            background-color: #fcfcfc;
            left: 0;
        }

        input[type=checkbox]:checked + label:after{
            content: "";
            background-color: #ddbb89;
            position: absolute;
            z-index: 4;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11' height='6' viewBox='0 0 11 6' fill='none'%3E%3Cpath d='M10 1L5.5 4.5L1 1' stroke='%23F58220' stroke-width='1.5'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            height: 6px;
            width: 10px;
            background-position: center;
            top: 8px;
            left: 5px;
            background-color: transparent;
            border-radius: 0;
        }
    }

    textarea{
        width: 100%;
        height: 100px;
        border: 2px solid #e7e7e7;
        outline: none;
        background-color: #fff;
        padding: 25px 12px 8px 12px ;
        font-size: 16px;
        color: #111;
        border-radius: 5px;
        min-width: 120px;
        font-weight: 500;
        display: block;
        background-color: #fcfcfc;
        font-size: 14px;
        color: #888;
    }

    select,  input{
        width: 100%;
        min-width: 220px;
        border: 2px solid #e7e7e7;
        outline: none;
        background-color: #fff;
        padding: 25px 12px 8px 12px;
        font-size: 16px;
        color: #111;
        border-radius: 5px;
        display: block;
        -webkit-appearance: initial;
        background-color: #fcfcfc;
        font-size: 14px;
        color: #888;
    }

    .input-readonly{
        background-color: #f1f2f7;
        pointer-events: none;
        box-shadow: inset 0 0 5px rgba(0,0,0,0.25);
    }

     input, select{
        &.non-input{
            pointer-events: none; 
            opacity: 0.5;
            background: #e7e7e7;
        }        
    }
}

.single-from__wrap{
    display: block;
    border: 2px solid #e7e7e7;
    padding: 20px;
    border-radius: 5px;

}

form{
    button[type="submit"], ._btn{
        display: block;
        width: 180px;
        background-color: $color;
        color: $text_color;;
        padding: 15px 15px;
        text-align: center;
        border-radius: 5px;
        border: none;
        box-shadow: 0 2px 5px rgba(0,0,0,0.2); 
        font-weight: 800;
        text-transform: uppercase;
        font-size: 14px;
        cursor: pointer;
        margin-top: 10px;
        height: 52px;
        margin-bottom: 10px;
    }

    p{
        font-size: 15px;
        margin-bottom: 15px;
        span{
            font-weight: bold;
        }
    }

    .log{
        div{
            display: block;
            width: 100%;
            background-color: #f9c9c9;
            padding: 15px 20px;
            border-radius: 10px;
            margin: 10px 0;
        }        
    }
    
}

.input-wrap-multiple{
    ._multiple-add-btn{
        display: block;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: normal;
        color: #111;
        border-radius: 5px;
        background-color: #fcfcfc;
        border: 2px solid #e7e7e7;
        cursor: pointer;
        margin-top: 10px;
    }

    
}

.order-summary{
    h4{
        font-size: 18px;
    }

    .order-short-info{
        display: none;
    }

    .order-property_list{
        display: none;
    }

    .order-create__left{
        padding: 0;
        border: 0;
    }

    .order-product-list{
        padding: 0;
        background: none;

        .order-product-list-item{
            border: 1px solid #ccc;
            border-radius: 5px;
        }
    }

    .input-wrap{
        .input-name{
            position: relative;
            display: block;
            font-size: 11px;
            font-weight: normal;
            top: 0;
            left: 0;
        }

        ._multiple-add-btn, ._remove-multiple-item{
            display: none!important;
        }

         input, select, textarea{
            font-size: 15px;
            margin-bottom: 5px;
            border: none;
            height: auto!important;
            box-shadow: none;
            padding: 0!important;
            background-color: #fff;
            pointer-events: none;
            color: #000;
            font-weight: normal;
        }
        

        .select2-container--default .select2-selection--single{
            border: none!important;
            background-color: #fff;
            padding: 0!important;
            height: auto!important;
        }

        .select2-container--default .select2-selection--single .select2-selection__rendered{
            font-size: 15px;
            margin-bottom: 5px;
            border: none;
            height: auto!important;
            box-shadow: none;
            padding: 0!important;
            background-color: #fff;
            pointer-events: none;
            color: #000;
            font-weight: normal;
        }

        .select2-container--default .select2-selection--single .select2-selection__arrow{
            display: none;
        }
    }
}

.input-multiple-item, ._select-product-inputs{
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    .select2-container{
        width: calc(100% - 50px)!important;
    }
    

    ._remove-multiple-item, ._select-product-inputs-remove{
        display: block;
        width: 40px;
        height: 49.59px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: normal;
        color: #111;
        border-radius: 5px;
        background-color: #fcfcfc;
        border: 2px solid #e7e7e7;
        cursor: pointer;
        margin-top: 10px;            
    }

    ._select-product-inputs-remove{
        height: 80px;
        transform: translateY(-5px);
    }

    ._remove-image{
        display: none;
    }

     input, select{
        width: calc(100% - 50px);
        margin-top: 8px;
        padding: 12px 12px 8px 12px !important;
    }
}

.input-wrap-multiple_first-file{
    display: flex;
    align-items: center;
    justify-content: space-between;

     input, select{
        width: calc(100% - 50px); 
    }

    ._remove-image{
        display: block;
        width: 40px;
        height: 67px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: normal;
        color: #111;
        border-radius: 5px;
        background-color: #fcfcfc;
        border: 2px solid #e7e7e7;
        cursor: pointer;
        
    }
}

.select-data__wrap{
    margin-bottom: 20px;
    background-color: rgb(235 235 235);
    box-shadow: 0 0 5px rgba(0,0,0,0.15);
    padding: 15px;
    .select-data__wrap-title{
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 5px;
    }

    .select-data__content{        

        .select-data__item{
            margin: 10px 0;
            display: flex;
            align-items: center;

            .input-wrap{
                font-size: 11px;
                width: calc(50% - 25px);
                margin-right: 10px;
                margin-bottom: 0;
            }

            .remove{
                cursor: pointer;
                height: 62.2px;
                font-size: 30px;
                width: 30px;
                background-color: #fff;
                border: 2px solid #000;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #000;
                border-radius: 5px;
            }
        }
    }

    .select-data__item-add, .select-data-stage__item-add{
        background-color: $color;
        padding: 10px;
        font-size: 16px;
        font-weight: bold;
        color: #fff;
        margin-top: 10px;
        text-align: center;
        border-radius: 5px;
        box-shadow: 0 0 5px rgba(0,0,0, 0.15);
        cursor: pointer;
    }
}

.login-wrap, .code-check{
    width: 450px;
    max-width: 100%;
    margin-top: 40px;
    padding: 20px;
    margin-left: auto;
    margin-right: auto;

    .login-title{
        text-transform: uppercase;
        font-size: 20px;
        margin-bottom: 20px;
    }
}

.login-code-repeat{
    margin-top: 10px;
    color: #000;
    font-size: 15px;
    text-decoration: underline;

    &.disable{
        opacity: 0.5;
        pointer-events: none;
    }    
}


form[name="sortForm"]{
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .input-wrap{
        margin: 0;
        margin-right: 15px;
    }

    button[type="submit"], ._btn{
        height: 59px;
    }
}