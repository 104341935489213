.cnt-row{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    .cnt-col-33{
        width: 33%;
        padding: 0 15px;
    }

    .cnt-col-66{
        width: 66%;
        padding: 0 15px;
    }

    .cnt-col-100{
        width: 100%;
        padding: 0 15px;
    }

    .cnt-col-50{
        width: 50%;
        padding: 0 15px;
    }

    .cnt-col-100{
        width: 100%;
        padding: 0 15px;
    }
}


@media screen and (max-width: 1200px){
    .cnt-row{
        flex-wrap: wrap;

        .cnt-col-33, .cnt-col-66, .cnt-col-50{
            width: 100%;
        }
    }    
}