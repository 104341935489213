@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

$color: #ddbb89;
$text_color: #fff;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: "Roboto";
    background-color: #fff;
    overflow-x: hidden;
}

ul {
    padding-inline-start: 0px;
}

h1 {
    font-size: 25px;
    margin-bottom: 20px;
    font-weight: 800;
    margin-bottom: 30px;
}

.short-order_product-item h5 {
    font-size: 1.5rem;
}

.order-short-info {
    display: block;
    margin-top: 15px;
    margin-bottom: 15px;
    background-color: #fcfcfc;
    border: 2px solid #e7e7e7;
    padding: 20px;

    p {
        font-size: 16px;
        margin-bottom: 10px;
    }
}

.order-short-info-row {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 5px;
    align-items: center;
}
.order-short-info-row b {
    font-size: 12px;
    font-weight: normal;
    line-height: 1;
    color: #888;
    display: flex;
    grid-column-start: 1;
    grid-column-end: 3;
}
.order-short-info-row span {
    display: flex;
    grid-column-start: 3;
    grid-column-end: 6;
}
.short-product-galery {
    display: flex;
    margin: 30px 0;
    gap: 10px;
}
.short-product-galery .product-galery-item {
    min-width: 211px;
    width: 33%;
    aspect-ratio: 1/1.4;
    position: relative;
}
.short-product-galery .galery-item {
    position: absolute;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
}
@media (max-width: 640px) {
    .order-short-info-row {
        flex-direction: column;
    }
    .order-short-info-row b,
    .order-short-info-row span {
        grid-column-start: 1;
        grid-column-end: 6;
    }
    .short-product-galery .product-galery-item {
        min-width: 0;
        width: 48%;
    }
}


.pagination {
    margin-top: 40px;
    margin-bottom: 120px;
    .flex-1 {
        display: none !important;
    }

    svg {
        height: 15px !important;
    }

    span[aria-current="page"] {
        padding: 0 !important;
    }

    a,
    span[aria-current="page"] span {
        width: 19px !important;
        padding: 8px 12px !important;
    }
}

header {
    width: 100%;
    border-bottom: 1px solid #e7e7e7;
    padding: 10px 35px;
    position: fixed;
    z-index: 100;
    background-color: #fff;
    top: 0;
    left: 0;

    .logo-text {
        font-weight: bold;
        font-size: 18px;
        display: flex;
        align-items: center;
        margin-bottom: 0;

        img {
            height: 25px;
            margin-right: 10px;
        }
    }

    .header-flex {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
    }
}

.header-user__wrap {
    display: flex;
    align-items: center;
    position: relative;
    border: 2px solid #e7e7e7;
    border-radius: 8px;
    background-color: #fff;
    padding: 8px;

    .header-user__image {
        margin-right: 15px;
        img {
            height: 32px;
        }
    }

    .header-user__name {
        font-size: 13px;
        font-weight: bold;
        line-height: 1;
    }

    .header-user__role {
        font-size: 12px;
        font-weight: 300;
    }

    &.active {
        .header-user__wrap--hidden {
            display: block;
        }
    }

    .header-user__wrap--hidden {
        display: none;
        position: absolute;
        bottom: -10px;
        right: 0;
        transform: translateY(100%);
        background-color: #fff;
        width: 100px;

        border: 2px solid #ccc;
        &::before {
            display: block;
            content: "";
            width: 10px;
            height: 10px;
            border-left: 2px solid #ccc;
            border-top: 2px solid #ccc;
            background-color: #fff;
            position: absolute;
            right: 30px;
            top: 0;
            transform: rotate(45deg) translateY(-100%);
            z-index: -1;
        }
        a {
            display: block;
            font-size: 12px;
            padding: 3px 5px;
            text-align: center;
            color: #000;

            &:hover {
                background-color: #000;
                color: #fff;
                text-decoration: none;
            }
        }
    }
}

#content {
    width: 100%;

    display: flex;

    .left-menu {
        width: 100%;
        position: sticky;
        top: 0;
        padding: 0 20px;
        height: 100vh;
        width: 340px;
        background-color: #f6f4f5;
        padding: 135px 0 20px 20px;

        ul {
            display: flex;
            flex-direction: column;
            margin-bottom: 5px;

            li {
                list-style-type: none;

                a {
                    font-size: 18px;
                    text-decoration: none;
                    font-weight: 400;
                    display: block;
                    padding: 5px 15px;
                    margin-right: 10px;
                    margin-bottom: 18px;
                    color: #010007;

                    display: flex;
                    align-items: center;

                    svg {
                        transform: translateY(-7%);
                        width: 23px;
                        height: 23px;
                        margin-right: 15px;
                    }

                    &:hover {
                        opacity: 0.7;
                    }
                }
            }
        }
    }

    #main {
        width: 100%;
        min-height: calc(100% - 120px);
        padding: 20px 20px;
        padding-top: 115px;
    }
}

#dashboard {
    h1 {
        margin-bottom: 50px;
    }

    .dashboard-table-title {
        margin-top: 30px;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 30px;
        display: flex;
        align-items: center;

        svg {
            height: 25px;
            margin-right: 15px;
        }
    }
}

.qrcode-footer {
    position: fixed;
    z-index: 100;
    bottom: 30px;
    width: 170px;
    background-color: $color;
    color: #111;
    text-align: center;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    font-weight: bold;

    img {
        height: 20px;
        margin-right: 10px;
    }
}

.modal-qr__wrap {
    position: fixed;
    z-index: 200;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(10px);
    display: none;

    .modal-qr__close {
        position: fixed;
        top: 20px;
        right: 20px;
        font-weight: bold;
        color: #000;
        font-size: 57px;
        cursor: pointer;
    }

    &.active {
        display: block;
    }

    .modal-qr__content {
        position: fixed;
        top: 50%;
        left: 50%;
        width: 70vw;
        height: 70vw;
        max-width: 50vh;
        max-height: 50vh;
        background-color: #000;
        transform: translate(-50%, -50%);
        background-size: cover;
    }
}

#table-list {
    padding: 20px 20px;
}

.btn_add {
    width: 160px;
    background-color: $color;
    color: $text_color;
    padding: 15px 15px;
    text-align: center;
    border-radius: 5px;
    border: none;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    font-weight: 800;
    text-transform: uppercase;
    font-size: 14px;
    cursor: pointer;
    height: 59px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;

    &:hover {
        opacity: 0.9;
        color: #fff;
        text-decoration: none;
    }
}

._btn {
    width: 160px;
    background-color: $color;
    color: $text_color;
    padding: 10px 5px;
    text-align: center;
    border-radius: 5px;
    border: none;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    font-weight: 800;
    text-transform: uppercase;
    font-size: 13px;
    cursor: pointer;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0px;
    margin-top: 18px;

    &:hover {
        opacity: 0.9;
        color: #fff;
        text-decoration: none;
    }
}

.client-card__item-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .client-card__wrap {
        width: calc(50% - 10px);

        &:hover {
            text-decoration: none;
        }
    }

    .client-card__item {
        display: block;
        text-decoration: none;
        color: #111;
        border: 2px solid #e7e7e7;
        border-radius: 5px;
        padding: 15px;
        margin-bottom: 15px;
        //box-shadow: 0 0 4px rgba(0,0,0,0.15);

        &:focus {
            text-decoration: none;
        }

        .name {
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 15px;

            svg {
                margin-right: 10px;
            }
        }

        .client-card__item-status {
            margin-top: 15px;
            span {
                display: inline-block;
                margin-right: 15px;
            }
        }

        .data-item {
            font-size: 15px;
            margin-bottom: 8px;
        }
    }
}

.order-type_list {
    width: calc(100% - 520px);
    max-width: 730px;
    display: flex;
    align-items: center;
    //margin-bottom: 30px;
    flex-wrap: wrap;

    .order-type_list-item {
        display: inline-block;
        width: 50%;
        border: 2px solid #e7e7e7;
        background-color: #fcfcfc;

        // &:first-child{
        //     border-right: none;
        // }

        // &:nth-child(2n+1){
        //     border-right: none;
        // }

        text-align: center;
        padding: 10px 8px 10px 8px;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 600;
        cursor: pointer;

        &.active {
            background-color: $color;
            color: #fff;
        }
    }
}

.tab-data {
    display: none;
    &.active {
        display: block;
    }
}

.table-order_data-item {
    margin-bottom: 5px;
    .table-order_data-name {
        font-weight: bold;
        margin-right: 5px;
        font-size: 12px;
    }
    .table-order_data-v {
        font-size: 12px;
    }
}

.custom-list-page {
    .cnt-col-33 {
        margin-bottom: 25px;
    }
    .custom-list__item {
        margin-bottom: 20px;
        border: 2px solid #e7e7e7;
        padding: 25px;
        border-radius: 8px;
        height: 100%;

        h3 {
            font-weight: normal;
            margin-bottom: 20px;
        }

        a {
            color: $color;
            font-size: 18px;
            display: block;
            margin-bottom: 5px;
        }
    }
}

.non-paynament-list {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 400px;

    border: 2px solid #e7e7e7;
    background-color: #fcfcfc;
    border-radius: 8px;

    span {
        font-weight: 500;
        font-size: 20px;
        text-align: center;
        opacity: 0.9;
    }
}

.part-pay {
    color: #d5c113;
    font-weight: 500;
}

.is-pay {
    color: #21d71f;
    font-weight: 500;
}

.no-pay {
    color: #f51919;
    font-weight: 500;
}

.order-product-list__wrap {
    .order-product-list {
        border-radius: 5px;
        box-shadow: 0 0 -10px rgba(0, 0, 0, 0.1);
        margin-top: 15px;
        background-color: #f6f4f5;
        padding: 20px;

        .order-product-list-item {
            padding: 20px;
            background-color: #fff;
            margin: 20px 0;

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }

            .order-product-list-item__r-product a {
                display: flex;
                align-items: center;
                margin-bottom: 15px;

                img {
                    display: block;
                    width: 60px;
                    margin-right: 10px;

                    .name {
                        font-size: 15px;
                    }
                }
            }

            .order-product-list-item__remove-wrap {
                display: flex;
                justify-content: flex-end;
            }
            .order-product-list-item__remove {
                display: block;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 20px;
                font-weight: normal;
                color: #111;
                border-radius: 5px;
                background-color: #fcfcfc;
                border: 2px solid #e7e7e7;
                cursor: pointer;
                padding: 0 10px;

                span {
                    font-size: 15px;
                    display: inline-block;
                    margin-left: 10px;
                }
            }
        }
    }
}

.cnt-tabs_nav-wrap {
    display: flex;
    align-items: center;

    .cnt-tab__nav {
        font-size: 20px;
        font-weight: normal;
        color: #afafaf;
        padding: 5px 5px;
        cursor: pointer;
        width: 220px;
        border-bottom: 2px solid #d1d1d1;
        text-align: center;

        &.active {
            color: $color;
            border-bottom: 2px solid $color;
        }
    }
}

.cnt-tabs__items {
    margin-top: 30px;
    .cnt-tabs__item {
        width: 100%;
        display: none;

        &.active {
            display: block;
        }
    }
}

.order-property_list {
    display: block;
    width: 100%;
    margin-top: 8px;
    display: flex;
    flex-wrap: wrap;

    .order-property_item {
        font-size: 12px;
        padding: 4px 5px;
        border: 2px solid #e7e7e7;
        margin-right: 5px;
        color: #888;
        cursor: pointer;
        margin-bottom: 3px;

        &.active {
            background-color: #ddbb89;
            color: #fff;
            cursor: pointer;
            border: 2px solid #ddbb89;
        }
    }

    .select2-container,
    select {
        max-width: 50% !important;
    }

    .select2-container--default .select2-selection--single,
    select {
        height: 55px !important;
    }
    .select2-container--default
        .select2-selection--single
        .select2-selection__rendered,
    select {
        padding-top: 20px !important;
    }
}

.input_flex {
    display: flex;
    justify-content: space-between;

    .input-wrap {
        width: calc(50% - 10px);
        input {
            min-width: 0px;
        }
    }
}
.order-create {
    max-width: 1250px;
    justify-content: space-between;

    .order-create__left {
        width: calc(100% - 520px);
        padding: 20px;
        border-radius: 0 0 8px 8px;
        border: 2px solid #e7e7e7;
    }

    .order-create__right {
        width: 500px;
        .order-create__content {
            position: sticky;
            top: 100px;

            .order-create__paynament-data {
                padding: 20px;
                border-radius: 8px;
                background-color: $color;
                p {
                    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
                    padding-bottom: 10px;

                    &:last-child {
                        border: none;
                        padding-bottom: 0;
                    }
                    b {
                        font-size: 13px;
                        font-weight: normal;
                        color: #fff;
                        display: block;
                        //margin-bottom: 10px;
                    }

                    span {
                        font-size: 25px;
                        color: #fff;
                        font-weight: bold;
                    }
                }
            }
        }
    }
}

._paynament-add-btn {
    width: 140px;
    background-color: $color;
    color: $text_color;
    padding: 10px 5px;
    text-align: center;
    border-radius: 5px;
    border: none;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 11px;
    cursor: pointer;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0px;
    margin-top: 18px;

    &:hover {
        opacity: 0.9;
        color: #fff;
        text-decoration: none;
    }
}

.paynament-add__wrap {
    display: flex;
    justify-content: space-between;
    max-width: 1400px;
    .paynament-add__left {
        width: calc(100% - 400px);
        table {
            width: 100%;
            max-width: 100%;
            min-width: 0;
        }
    }

    .paynament-add__right {
        width: 350px;
    }
}

.table-sort-top__tab {
    //margin-right: 15px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .table-sort-top__tab-name {
        font-size: 12px;
        //margin-bottom: 4px;
        margin-right: 10px;
        color: #8b8b8b;
    }

    .table-sort-top__tab-select {
        display: flex;
        align-items: center;
    }
    .table-sort-top__tab-item {
        width: 25px;
        height: 25px;
        margin-right: 10px;
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
        margin-right: 10px;
        cursor: pointer;

        opacity: 0.5;

        &.active {
            opacity: 1;
        }
    }

    .table-view-cart {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='45' height='36' viewBox='0 0 45 36' fill='none'%3E%3Crect y='14' width='20' height='8' fill='%23DDBB89'/%3E%3Crect x='25' y='14' width='20' height='8' fill='%23DDBB89'/%3E%3Crect width='20' height='8' fill='%23DDBB89'/%3E%3Crect x='25' width='20' height='8' fill='%23DDBB89'/%3E%3Crect y='28' width='20' height='8' fill='%23DDBB89'/%3E%3Crect x='25' y='28' width='20' height='8' fill='%23DDBB89'/%3E%3C/svg%3E");
    }

    .table-view-list {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='45' height='36' viewBox='0 0 45 36' fill='none'%3E%3Crect width='45' height='8' fill='%23DDBB89'/%3E%3Crect y='14' width='45' height='8' fill='%23DDBB89'/%3E%3Crect y='28' width='45' height='8' fill='%23DDBB89'/%3E%3C/svg%3E");
    }
}

.order-form-page {
    .order-create {
        display: none;
        justify-content: space-between;

        &.active {
            display: flex;
        }
    }
}

._select-product-wrap {
    .product-galery {
        display: flex;
        flex-wrap: wrap;

        .product-galery-item {
            position: relative;
            width: calc(33% - 10px);
            margin: 10px 0;

            &:first-child {
                margin-right: 10px;
            }

            &:nth-child(4n) {
                margin-right: 10px;
            }

            &:nth-child(3n) {
                margin-left: 10px;
            }
            .galery-item {
                position: relative;
                display: block;
                padding-top: 120%;
                background-size: cover;
                background-position: center;
            }
        }
    }
}

@media screen and (max-width: 1550px) {
    .order-type_list {
        width: calc(100% - 320px);
    }

    .order-create {
        .order-create__left {
            width: calc(100% - 320px);
        }

        .order-create__right {
            width: 290px;
        }
    }
}

@media screen and (max-width: 1200px) {
    .qrcode-footer {
        padding: 20px 0;
        left: 0;
        transform: none;
        width: 100%;
        bottom: 0;
    }

    .custom-list-page {
        .custom-list__item {
            height: auto;
        }
    }

    .order-type_list {
        width: 100%;
        max-width: 100%;
        .order-type_list-item {
            font-size: 11px;
            height: 75px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .client-card__item-wrap {
        display: block;
        .client-card__wrap {
            width: 100%;
        }
    }

    .order-create {
        flex-direction: column;

        .order-create__left {
            width: 100%;
            margin-bottom: 15px;

            button[type="submit"] {
                width: 100%;
            }
        }

        .order-create__right {
            width: 100%;
        }
    }

    .btn_add {
        width: 100%;
    }

    header {
        padding: 10px 25px;
    }

    #myChart {
        display: none !important;
    }

    #content {
        padding-top: 70px;
        padding-bottom: 180px;

        #main {
            padding: 0 10px;
            padding-top: 40px;
        }
    }

    #content .left-menu {
        position: fixed;
        bottom: 0;
        padding-top: 10px;
        background-color: #fff;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        padding-top: 20px;
        height: auto;
        top: auto;
        width: 100%;
        z-index: 100;
    }

    #content .left-menu.manager-menu {
        bottom: 41px;
        padding: 10px 15px;
    }

    #content .left-menu {
        position: fixed;
        bottom: 0;
        overflow: hidden;
        padding-top: 10px;
        background-color: #fff;
        //box-shadow: 0 2px 5px rgba(0,0,0,0.2);
        border-top: 2px solid #e7e7e7;
    }

    #content .left-menu ul {
        overflow: auto;
        width: auto;
        display: block;
        white-space: nowrap;
    }

    #content .left-menu ul li {
        display: inline-block;
        margin-top: 0 !important;
    }

    #content .left-menu ul li a {
        font-size: 13px;

        svg {
            margin-right: 10px;
        }
        //border: 2px solid $color;
    }

    .order-create .order-create__left {
        padding: 30px 15px;
    }

    .order-product-list__wrap .order-product-list {
        padding: 30px 10px;

        .order-product-list-item {
            padding: 10px;
        }
    }

    .input_flex {
        .input-wrap {
            width: calc(50% - 5px);
        }
    }

    .order-create {
        .order-create__right {
            .order-create__content {
                z-index: 50;

                .order-create__paynament-data {
                    position: fixed;
                    bottom: 85px;
                    width: 100%;
                    left: 0;
                    z-index: 1000;
                    display: flex;
                    justify-content: space-between;

                    p {
                        font-size: 15px;
                        margin-bottom: 0;
                        border-bottom: 0;
                        padding-bottom: 10px;

                        span {
                            font-size: 17px;
                        }
                        b {
                            font-size: 9px;
                        }
                    }
                }
            }
        }
    }
}

@import "col/col";
@import "widgets/widgets";
@import "form/form";
@import "table/table";
@import "modal/modal";
@import "prodaction/prodaction";
