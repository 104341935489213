
.table-wrap
    width: 100%
    overflow: auto
    margin-bottom: 30px


.table-sort-top
    display: flex
    justify-content: space-between
    align-items: center
    margin-bottom: 15px

#table-list
    margin-top: 40px

table
    width: 100%    
    min-width: 760px
    overflow-y: auto
    border-bottom: 1px solid #e7e7e7
    thead
        margin-bottom: 20px
        padding: 20px
        background-color: #252525
        top: 0px
        position: sticky
        border-radius: 10px 10px 0 0
    th
        font-size: 13px
        color: #fff
        padding: 15px 10px        
        font-weight: 400

        &:first-child
            border-radius: 10px 0 0 0 

        &:last-child
            border-radius: 0 10px 0 0

    .th-sort
        cursor: pointer
        &.desc
            &::after
                content: ""
                display: inline-block
                margin-left: 4px
                background-size: 100%
                background-position: center
                background-repeat: no-repeat
                width: 8px
                height: 18px
                transform: translateY(4px)
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='17' viewBox='0 0 8 17' fill='none'%3E%3Cpath d='M4.06631 16.4619C3.97155 16.5913 3.8185 16.6753 3.64583 16.6753C3.47316 16.6753 3.32012 16.5913 3.22536 16.4619L0.152549 13.3891C-0.0508495 13.1857 -0.0508495 12.8559 0.152549 12.6525C0.355947 12.4491 0.68572 12.4491 0.889118 12.6525L3.125 14.8884V0.520833C3.125 0.233185 3.35819 0 3.64583 0C3.93348 0 4.16667 0.233185 4.16667 0.520833V14.8884L6.40255 12.6525C6.60595 12.4491 6.93572 12.4491 7.13912 12.6525C7.34252 12.8559 7.34252 13.1857 7.13912 13.3891L4.06631 16.4619Z' fill='white'/%3E%3C/svg%3E")
        &.asc
            &::after
                content: ""
                display: inline-block
                margin-left: 4px
                background-size: 100%
                background-position: center
                background-repeat: no-repeat
                width: 8px
                height: 18px
                transform: translateY(4px)
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='17' viewBox='0 0 8 17' fill='none'%3E%3Cpath d='M3.22535 0.213422C3.32011 0.0840297 3.47315 3.48087e-06 3.64582 3.49597e-06C3.81849 3.51106e-06 3.97154 0.0840298 4.0663 0.213422L7.13911 3.28623C7.34251 3.48963 7.34251 3.8194 7.13911 4.0228C6.93571 4.2262 6.60594 4.2262 6.40254 4.0228L4.16666 1.78692L4.16666 16.1545C4.16666 16.4422 3.93347 16.6753 3.64582 16.6753C3.35817 16.6753 3.12499 16.4422 3.12499 16.1545L3.12499 1.78692L0.889108 4.0228C0.68571 4.2262 0.355936 4.2262 0.152537 4.0228C-0.0508605 3.8194 -0.0508605 3.48963 0.152538 3.28623L3.22535 0.213422Z' fill='white'/%3E%3C/svg%3E")

    
    td
        padding: 12px 10px 
        min-width: 50px
        font-size: 12px
        border-right: 1px solid #e7e7e7

        &:first-child
            border-left: 1px solid #e7e7e7

        .icon
            font-size: 13px
            display: inline-block
            margin-right: 10px
            opacity: 0.56

    tbody tr        
        border-bottom: 2px solid #f4f4f4
        &:last-child         
            border-bottom: none
        &:hover
            background-color: #f4f4f4
            transition: all 0.2s ease-in-out    

    .center
        text-align: center
    .right
        text-align: right

    .bold
        font-weight: bold

    .id
        font-size: 12px
        max-width: 60px
        width: 60px

    .category-item
        font-size: 12px
        display: inline-block
        padding: 4px 8px 
        color: var(--text-color)
        background-color: var(--main-color)
        border-radius: 10px

    .link-change
        color: var(--main-color)
        background-color: transparent
        border: 1px solid var(--main-color)
        padding: 4px 8px
        font-size: 11px
        border-radius: 10px
        text-decoration: none
        text-align: right

    .td-image
        max-width: 200px
        width: 190px
    .td-id
        width: 70px

    .btn_add
        background: none!important
        color: #1f6299
        padding: 0
        box-shadow: none!important
        font-weight: normal
        margin-bottom: 0
        height: 22px

        &:hover
            color: #1f6299

    ._table-remove-wrap
        width: 100%
        display: flex
        justify-content: center
        align-items: center
        ._table-remove-btn
            cursor: pointer
            font-size: 10px
            display: block
            width: 70px
            text-align: center
            padding: 4px
            border: 1px solid #111
            color: #111
            border-radius: 5px

    .td-paynament_in
        font-weight: 500
        color: #89bb64
    .td-paynament_out
        font-weight: 500
        color: #cd2424

table.mini-table
    tr
        td, th
            font-size: 10px

.select2-container
    width: 100%!important

.select2-container--default 
    .select2-selection--single
        border: 2px solid #e7e7e7!important
        background-color: #fcfcfc
        //height: 80.39px
        border: none
        height: 65.59px
    .select2-selection--single .select2-selection__rendered
        padding: 25px 12px 8px 12px
        font-size: 14px
        color: #888
    .select2-selection--single .select2-selection__arrow
        height: 55.39px
    .select2-selection--single .select2-selection__clear
        height: 55.39px

._select-product-wrap
    .select2-selection--single
        height: 80.39px!important

.input-multiple-item
    .select2-selection--single
        height: 49.59px
        .select2-selection__rendered
            padding: 12px 12px 8px 12px    

    .select2-container
        transform: translateY(5px)        

.select2-container--default
    .select2-results
        .select2-results__options
            .select2-results__option
                img
                    display: inline-block
                    margin-right: 10px
                    height: 100px

.select2-selection__rendered
    img
        width: 30px
        margin-right: 15px
        

/* END SELECT2 */
.operation-table
    //background-color: #fff
    margin-top: 20px
    max-width: 300px
    .operation-table_flex
        display: flex

        .input-wrap 
            .input-name
                font-size: 11px
            select
                font-size: 14px
                padding-top: 21px
                background-color: #fff

        button
            margin-left: 20px
            width: 100%
            display: block
            padding: 12px 12px
            font-size: 13px
            font-weight: bold
            text-align: center
            background-color: $color
            border: none
            color: #fff
            opacity: 1
            height: 58px
            transition: all 0.25s ease-in-out
            border-radius: 6px
            box-shadow: 0 5px 6px rgba(0,0,0,0.15)
            &:hover
                opacity: 0.8
.checkbox-id
    width: 40px
    .check-box
        margin-left: 15px
        cursor: pointer
        width: 18px
        height: 18px
        border-radius: 4px
        box-shadow: 0 0 2px rgba(0,0,0,0.9)

        &_active 
            background-color: $color
            background-image: url('/image/svg/ok.svg')
            background-size: 100%
            background-position: center
            background-repeat: no-repeat

.load-full-screen
    opacity: 0
    visibility: hidden
    transition: visibility .3s linear,opacity .3s ease-in-out
    
    position: fixed
    top: 0px
    left: 0px
    width: 100%
    height: 100%
    background-color: rgba(255, 255, 255, 0.25)

    .load-gif
        width: 50px
        height: 50px
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)

    &_active
        visibility: visible
        opacity: 1
        transition: visibility .3s linear,opacity .3s ease-in-out

@media screen and (max-width: 1100px)
    .table-sort-top
        display: block

    table
        th
            padding: 3px 5px
        td, th
            font-size: 12px
