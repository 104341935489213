.widget-bill-list__wrap{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.widget-bill__wrap{
    width: calc(25% - 15px);    
    display: block;
    padding: 30px;
    display: flex;
    align-items: flex-end;
    height: 200px;
    background-color: #fff;
    border-radius: 20px;
    margin-bottom: 20px;
    //box-shadow: 0 2px 5px rgba(0,0,0,0.2); 
    margin-bottom: 30px;
    position: relative;

    border: 2px solid #e7e7e7;

    &.widget-bill__wrap-square{
        height: 250px;
        width: calc(33% - 15px);        
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .widget-bill__title{
            font-size: 15px;
            text-align: center;
        }

        .widget-bill__price{
            font-size: 50px;
            text-align: center;
        }
    }

    &.clicable{
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        &:hover{
            background-color: #fff3e2;
        }
    }

    .widget-bill__link-to{
        position: absolute;
        background-color: rgba(255,255,255, 1);
        top: 20px;
        right: 20px;
        display: block;
        width: 40px;
        height: 40px;
        background-size: 60%;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 100%;
        border: 1px solid #e7e7e7;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23ddbb89'%3E%3Cpath d='M6 18L18 6M18 6H10M18 6V14' stroke='%23ddbb89' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    
        transition: all 0.25s ease-in-out;
        opacity: 1;
        
        &:hover{
            width: 50px;
            height: 50px;
            top: 15px;
            right: 15px;
        }
    }

    .widget-bill__img-content{
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        img, svg{
            width: 60%;
            max-height: 80%;
        }

        span{
            margin-top: 10px;
        }
    }

    .widget-bill__title{
        font-size: 15px;
        font-weight: 300;
        // color: $text_color;
        margin-bottom: 0px;
    }

    .widget-bill__price{
        font-weight: bold;
        font-size: 35px;
        // color: $text_color;
    }

    &.widget-bill__wrap-dark{
        background-color: #f7f7f7;
    
        .widget-bill__title, .widget-bill__price{
            color: #111;
        }
    }
}


@media screen and (max-width: 1200px){
    .widget-bill__wrap{
        width: calc(50% - 8px);
        margin-bottom: 16px;
        padding: 15px;
        height: 130px;

        .widget-bill__link-to{
            top: 10px;
            right: 10px;
            width: 35px;
            height: 35px;            
            
            &:hover{
                width: 45px;
                height: 45px;
                top: 5px;
                right: 5px;
            }
        }
    
        .widget-bill__title{
            font-size: 12px;
        }
    
        .widget-bill__price{
            font-size: 20px;
        }
    }


}